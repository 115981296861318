import { info } from "@/lib/alert";
import { AuthClient } from "@/lib/auth";
import { useStore } from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { AccountType } from "@/lib/account";
import { SetupClient } from "@/lib/setup";
import { HTTPError, HTTPStatus } from "@/lib/http";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/NotFoundView.vue"),
  },
  {
    path: "/error",
    name: "internal-server-error",
    component: () => import("../views/InternalServerErrorView.vue"),
  },
  {
    path: "/",
    redirect: () => {
      const store = useStore();

      if (store.account) {
        if (store.account.type === AccountType.Coach) {
          return { path: `/account/${store.account.id}/menu` };
        }

        if (store.account.type === AccountType.Client) {
          return { path: `/account/${store.account.id}/dashboard` };
        }
      }

      return { name: "login" };
    },
  },
  {
    path: "/account/:accountId/menu",
    name: "menu",
    props: true,
    component: () => import("../views/MenuView.vue"),
  },
  {
    path: "/account/:accountId/metrics",
    name: "metrics",
    props: true,
    component: () => import("../views/metrics/MetricView.vue"),
  },
  {
    path: "/account/:accountId/progress",
    name: "progress",
    props: true,
    component: () => import("../views/progress/ProgressView.vue"),
  },
  {
    path: "/account/:accountId/progress/list",
    name: "progress-list",
    props: true,
    component: () => import("../views/progress/ListView.vue"),
  },
  {
    path: "/account/:accountId/progress/create",
    name: "progress-create",
    props: true,
    component: () => import("../views/progress/CreateView.vue"),
  },
  {
    path: "/account/:accountId/progress/compare",
    name: "progress-compare",
    props: true,
    component: () => import("../views/progress/CompareView.vue"),
  },
  {
    path: "/account/:accountId/height",
    name: "height",
    props: true,
    component: () => import("../views/metrics/height/HeightView.vue"),
  },
  {
    path: "/account/:accountId/height/create",
    name: "height-create",
    props: true,
    component: () => import("../views/metrics/height/RecordView.vue"),
  },
  {
    path: "/account/:accountId/height/list",
    name: "height-list",
    props: true,
    component: () => import("../views/metrics/height/ListView.vue"),
  },
  {
    path: "/account/:accountId/weight",
    name: "weight",
    props: true,
    component: () => import("../views/metrics/weight/WeightView.vue"),
  },
  {
    path: "/account/:accountId/weight/create",
    name: "weight-create",
    props: true,
    component: () => import("../views/metrics/weight/RecordView.vue"),
  },
  {
    path: "/account/:accountId/weight/list",
    name: "weight-list",
    props: true,
    component: () => import("../views/metrics/weight/ListView.vue"),
  },
  {
    path: "/account/:accountId/weight/goal/create",
    name: "weight-goal-create",
    props: true,
    component: () => import("../views/metrics/weight/CreateGoalView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/RegisterView.vue"),
  },
  {
    path: "/account/:accountId/auth/change-password",
    name: "account-password-change",
    props: true,
    component: () => import("../views/auth/ChangePasswordView.vue"),
  },
  {
    path: "/account/:accountId/profile/create",
    name: "profile-create",
    props: true,
    component: () => import("../views/profile/CreateView.vue"),
  },
  {
    path: "/account/:accountId/profile",
    name: "profile",
    props: true,
    component: () => import("../views/profile/ProfileView.vue"),
  },
  {
    path: "/account/:accountId/workouts",
    name: "workouts",
    props: true,
    component: () => import("../views/workout/WorkoutView.vue"),
  },
  {
    path: "/account/:accountId/workouts/create",
    name: "workouts-create",
    props: (route) =>
      Object.assign({}, route.params, { routineId: route.query["routineId"] }),
    component: () => import("../views/workout/CreateView.vue"),
  },
  {
    path: "/account/:accountId/workouts/exercises/list",
    name: "workout-exercises-list",
    props: true,
    component: () => import("../views/workout/CreateExerciseListView.vue"),
  },
  {
    path: "/account/:accountId/workouts/exercises/create",
    name: "workout-exercises-create",
    props: true,
    component: () => import("../views/workout/CreateExerciseView.vue"),
  },
  {
    path: "/account/:accountId/workouts/:workoutId/exercises/create",
    name: "workout-edit-exercises-create",
    props: true,
    component: () => import("../views/workout/CreateExerciseView.vue"),
  },
  {
    path: "/account/:accountId/workouts/:workoutId",
    name: "workout-view",
    props: true,
    component: () => import("../views/workout/DetailView.vue"),
  },
  {
    path: "/account/:accountId/workouts/:workoutId/edit",
    name: "workout-edit",
    props: true,
    component: () => import("../views/workout/EditView.vue"),
  },
  {
    path: "/account/:accountId/workouts/:workoutId/exercises/edit",
    name: "workout-exercises-list-edit",
    props: true,
    component: () => import("../views/workout/EditExerciseListView.vue"),
  },
  {
    path: "/account/:accountId/workouts/:workoutId/exercises/:workoutExerciseId/edit",
    name: "workout-exercises-edit",
    props: true,
    component: () => import("../views/workout/EditExerciseView.vue"),
  },
  {
    path: "/account/:accountId/workouts/list",
    name: "workout-list",
    props: true,
    component: () => import("../views/workout/ListView.vue"),
  },

  {
    path: "/account/:accountId/workouts/:workoutId/rate",
    name: "workout-rate",
    props: true,
    component: () => import("../views/workout/CreateRatingView.vue"),
  },
  {
    path: "/account/:accountId/clients",
    name: "client",
    props: true,
    component: () => import("../views/client/ClientView.vue"),
  },
  {
    path: "/account/:accountId/clients/invite",
    name: "client-invite",
    props: true,
    component: () => import("../views/client/InviteView.vue"),
  },
  {
    path: "/account/:accountId/clients/list",
    name: "client-list",
    props: true,
    component: () => import("../views/client/ListView.vue"),
  },
  {
    path: "/account/:accountId/coach/code",
    name: "coach-code",
    props: true,
    component: () => import("../views/coach/CodeView.vue"),
  },
  {
    path: "/account/:accountId/step",
    name: "step",
    props: true,
    component: () => import("../views/metrics/step/StepView.vue"),
  },
  {
    path: "/account/:accountId/step/create",
    name: "step-create",
    props: true,
    component: () => import("../views/metrics/step/RecordView.vue"),
  },
  {
    path: "/account/:accountId/step/list",
    name: "step-list",
    props: true,
    component: () => import("../views/metrics/step/ListView.vue"),
  },
  {
    path: "/account/:accountId/step/goal/create",
    name: "step-goal-create",
    props: true,
    component: () => import("../views/metrics/step/CreateGoalView.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    props: true,
    component: () => import("../views/SettingsView.vue"),
  },
  {
    path: "/account/:accountId/training",
    name: "training",
    props: true,
    component: () => import("../views/training/TrainingView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/routines/list",
    name: "training-plan-routine-list",
    props: true,
    component: () => import("../views/training/plan/CreateRoutineListView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/routines/create",
    name: "training-plan-routine-create",
    props: true,
    component: () => import("../views/training/plan/CreateRoutineView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/create",
    name: "training-plan-edit-routine-create",
    props: true,
    component: () => import("../views/training/plan/CreateRoutineView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/routines/exercises/list",
    name: "training-plan-routine-exercise-list",
    props: true,
    component: () =>
      import("../views/training/plan/CreateRoutineExerciseListView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/exercises/list",
    name: "training-plan-edit-routine-exercise-list",
    props: true,
    component: () =>
      import("../views/training/plan/CreateRoutineExerciseListView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/routines/exercises/create",
    name: "training-plan-routine-exercise-create",
    props: true,
    component: () =>
      import("../views/training/plan/CreateRoutineExerciseView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/exercises/create",
    name: "training-plan-edit-routine-create-exercise-create",
    props: true,
    component: () =>
      import("../views/training/plan/CreateRoutineExerciseView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/:routineId/exercises/create",
    name: "training-plan-edit-routine-exercise-create",
    props: true,
    component: () =>
      import("../views/training/plan/CreateRoutineExerciseView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId",
    name: "training-plan-view",
    props: true,
    component: () => import("../views/training/plan/DetailView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/list",
    name: "training-plan-list",
    props: true,
    component: () => import("../views/training/plan/ListView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/create",
    name: "training-plan-create",
    props: true,
    component: () => import("../views/training/plan/CreateView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/edit",
    name: "training-plan-edit",
    props: true,
    component: () => import("../views/training/plan/EditView.vue"),
  },

  {
    path: "/account/:accountId/training/plans/assign",
    name: "training-plan-assign",
    props: true,
    component: () => import("../views/training/plan/AssignView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/:routineId",
    name: "training-plan-routine-view",
    props: true,
    component: () => import("../views/training/plan/RoutineView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/edit",
    name: "training-plan-routines-edit",
    props: true,
    component: () => import("../views/training/plan/EditRoutineListView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/:routineId/edit",
    name: "training-plan-routine-edit",
    props: true,
    component: () => import("../views/training/plan/EditRoutineView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/:routineId/exercises/edit",
    name: "training-plan-routine-exercises-edit",
    props: true,
    component: () =>
      import("../views/training/plan/EditRoutineExerciseListView.vue"),
  },
  {
    path: "/account/:accountId/training/plans/:trainingPlanId/routines/:routineId/exercises/:exerciseId/edit",
    name: "training-plan-routine-exercise-edit",
    props: true,
    component: () =>
      import("../views/training/plan/EditRoutineExerciseView.vue"),
  },
  {
    path: "/account/:accountId/exercises/:exerciseId",
    name: "exercise-history",
    props: true,
    component: () => import("../views/exercise/HistoryView.vue"),
  },
  {
    path: "/account/:accountId/exercises/list",
    name: "exercise-list",
    props: true,
    component: () => import("../views/exercise/ListView.vue"),
  },
  {
    path: "/account/:accountId/nutrition",
    name: "nutrition",
    props: true,
    component: () => import("../views/nutrients/NutrientView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/macros",
    name: "nutrition-macros",
    props: true,
    component: () => import("../views/nutrients/macros/MacroView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/macros/goal/create",
    name: "nutrition-macros-goal-create",
    props: true,
    component: () => import("../views/nutrients/macros/CreateGoalView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/micros",
    name: "nutrition-micros",
    props: true,
    component: () => import("../views/nutrients/micros/MicroView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/micros/create",
    name: "nutrition-micros-create",
    props: true,
    component: () => import("../views/nutrients/micros/RecordView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/micros/list",
    name: "nutrition-micros-list",
    props: true,
    component: () => import("../views/nutrients/micros/ListView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/macros/create",
    name: "nutrition-macros-create",
    props: (route) =>
      Object.assign({}, route.params, { macroId: route.query["copy"] }),
    component: () => import("../views/nutrients/macros/RecordView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/macros/list",
    name: "nutrition-macros-list",
    props: true,
    component: () => import("../views/nutrients/macros/ListView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/macros/:macroId/edit",
    name: "nutrition-macros-edit",
    props: true,
    component: () => import("../views/nutrients/macros/EditView.vue"),
  },
  {
    path: "/account/:accountId/feed",
    name: "feed",
    props: true,
    component: () => import("../views/feed/FeedView.vue"),
  },
  {
    path: "/account/:accountId/gyms",
    name: "gym",
    props: true,
    component: () => import("../views/gym/GymView.vue"),
  },
  {
    path: "/account/:accountId/gyms/create",
    name: "gym-create",
    props: true,
    component: () => import("../views/gym/CreateView.vue"),
  },
  {
    path: "/account/:accountId/gyms/list",
    name: "gym-list",
    props: true,
    component: () => import("../views/gym/ListView.vue"),
  },
  {
    path: "/account/:accountId/gyms/:gymId",
    name: "gym-view",
    props: true,
    component: () => import("../views/gym/DetailView.vue"),
  },
  {
    path: "/account/:accountId/setup",
    name: "setup",
    props: true,
    component: () => import("../views/SetupView.vue"),
  },
  {
    path: "/account/:accountId/coach/link",
    name: "coach-link",
    props: true,
    component: () => import("../views/coach/LinkView.vue"),
  },
  {
    path: "/account/:accountId/verify-email",
    name: "verify-email",
    props: true,
    component: () => import("../views/auth/VerifyEmailView.vue"),
  },
  {
    path: "/account/:accountId/payments",
    name: "payment",
    props: (route) =>
      Object.assign({}, route.params, {
        redirectStatus: route.query["redirect_status"],
      }),
    component: () => import("../views/payment/PaymentView.vue"),
  },
  {
    path: "/account/:accountId/payments/create",
    name: "payment-create",
    props: true,
    component: () => import("../views/payment/CreateView.vue"),
  },
  {
    path: "/account/:accountId/payments/edit",
    name: "payment-edit",
    props: true,
    component: () => import("../views/payment/EditView.vue"),
  },
  {
    path: "/account/:accountId/payments/list",
    name: "payment-list",
    props: true,
    component: () => import("../views/payment/ListView.vue"),
  },
  {
    path: "/account/:accountId/checkins/forms/create",
    name: "checkin-form-create",
    props: true,
    component: () => import("../views/checkin/CreateFormView.vue"),
  },
  {
    path: "/account/:accountId/checkins/forms/list",
    name: "checkin-form-list",
    props: true,
    component: () => import("../views/checkin/ListView.vue"),
  },
  {
    path: "/account/:accountId/checkins/forms/:formId",
    name: "checkin-form-preview",
    props: true,
    component: () => import("../views/checkin/PreviewFormView.vue"),
  },
  {
    path: "/account/:accountId/checkins",
    name: "checkin",
    props: true,
    component: () => import("../views/checkin/CheckinView.vue"),
  },
  {
    path: "/account/:accountId/checkins/assign",
    name: "checkin-form-assign",
    props: true,
    component: () => import("../views/checkin/AssignView.vue"),
  },
  {
    path: "/account/:accountId/checkins/forms/submit",
    name: "checkin-form-submit",
    props: true,
    component: () => import("../views/checkin/SubmitView.vue"),
  },
  {
    path: "/account/:accountId/checkins/submissions",
    name: "checkin-form-submission-list",
    props: true,
    component: () => import("../views/checkin/ListSubmissionsView.vue"),
  },
  {
    path: "/account/:accountId/checkins/forms/:formId/submissions/:submissionId",
    name: "checkin-form-submission-view",
    props: true,
    component: () => import("../views/checkin/SubmissionView.vue"),
  },
  {
    path: "/account/:accountId/communications",
    name: "communications",
    props: true,
    component: () => import("../views/communication/ListView.vue"),
  },
  {
    path: "/account/:accountId/dashboard",
    name: "dashboard",
    props: true,
    component: () => import("../views/dashboard/DashboardView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/fluids",
    name: "fluids",
    props: true,
    component: () => import("../views/nutrients/fluids/FluidView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/fluids/create",
    name: "fluids-create",
    props: true,
    component: () => import("../views/nutrients/fluids/RecordView.vue"),
  },
  {
    path: "/account/:accountId/nutrition/fluids/list",
    name: "fluids-list",
    props: true,
    component: () => import("../views/nutrients/fluids/ListView.vue"),
  },
  {
    path: "/account/:accountId/activity",
    name: "activity",
    props: true,
    component: () => import("../views/activity/ActivityView.vue"),
  },
  {
    path: "/account/:accountId/activity/create",
    name: "activity-create",
    props: true,
    component: () => import("../views/activity/RecordView.vue"),
  },
  {
    path: "/account/:accountId/activity/list",
    name: "activity-list",
    props: true,
    component: () => import("../views/activity/ListView.vue"),
  },
  {
    path: "/account/:accountId/activity/:activityId",
    name: "activity-detail",
    props: true,
    component: () => import("../views/activity/DetailView.vue"),
  },
  {
    path: "/account/:accountId/activity/:activityId/edit",
    name: "activity-edit",
    props: true,
    component: () => import("../views/activity/EditView.vue"),
  },
  {
    path: "/account/:accountId/sleep",
    name: "sleep",
    props: true,
    component: () => import("../views/metrics/sleep/SleepView.vue"),
  },
  {
    path: "/account/:accountId/sleep/create",
    name: "sleep-create",
    props: true,
    component: () => import("../views/metrics/sleep/RecordView.vue"),
  },
  {
    path: "/account/:accountId/sleep/list",
    name: "sleep-create",
    props: true,
    component: () => import("../views/metrics/sleep/ListView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const store = useStore();

  if (
    to.name === "login" ||
    to.name === "register" ||
    to.name === "internal-server-error"
  ) {
    return true;
  }

  if (!store.account) {
    return { name: "login" };
  }

  const auth = new AuthClient();
  const setup = new SetupClient();

  const handleUnauthorized = () => {
    store.clear();
    info("Your session has expired, please log in again");
    return { name: "login" };
  };

  try {
    const [authenticated, stages] = await Promise.all([
      auth.authenticated(),
      setup.find(store.account.id),
    ]);

    if (!authenticated) {
      return handleUnauthorized();
    }

    if (!stages.complete && to.name !== "setup" && from.name !== "setup") {
      return { path: `/account/${store.account.id}/setup` };
    }
  } catch (e) {
    const err = e as HTTPError;
    if (err.code === HTTPStatus.Unauthorized) {
      return handleUnauthorized();
    }

    return { name: "internal-server-error" };
  }

  return true;
});

export default router;
